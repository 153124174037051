import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Input, Button, Select, Spin } from "antd";
import {
  DeleteOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  getAnswerOptions,
  createNewSymptom,
  getCategoryById,
} from "../../../redux/symptomsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
function Create() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const symptomState = useSelector((state) => state.symptoms);
  const [category, setCategory] = useState({ name: "", _id: null });
  const [editData, setEditData] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(
    symptomState?.answerOptions?.data?.map((option) => ({
      label: option,
      value: option,
    })) || []
  );

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getCategoryById(id)).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setEditData(response?.payload);
          setCategory({
            _id: response?.payload?._id,
            name: response?.payload?.category?.en,
          });
          let updatedSubCat = response?.payload?.subCategories.map((subCat) => {
            return {
              name: subCat?.name?.en,
              _id: subCat._id,
              questions: subCat.questions.map((question) => {
                let options = question.options.map((option) => ({
                  label: option?.en,
                  value: option?.en,
                }));
                return {
                  question: question?.question?.en,
                  answerType: question.answerType,
                  isActive: question.isActive,
                  options: options,
                  _id: question._id,
                };
              }),
            };
          });
          setSubCategory(updatedSubCat);
        }
        setLoading(false);
      });
    }
    dispatch(getAnswerOptions());
  }, []);

  const addQuestion = (scidx) => {
    const existingQuestions = [...subCategory];
    existingQuestions[scidx].questions.push({
      question: "",
      answerType: null,
      isActive: true,
      options: [],
      _id: null,
    });
    setSubCategory(existingQuestions);
  };

  const inputRef = useRef(null);
  const handleQuestionChange = (subCatIdx, index, type, value) => {
    const existingQuestions = [...subCategory];
    if (
      existingQuestions[subCatIdx].questions &&
      existingQuestions[subCatIdx].questions[index]
    ) {
      existingQuestions[subCatIdx].questions[index][type] = value;
    }
    setSubCategory(existingQuestions);
  };

  // Delete the question from the questions array
  const handleQuestionDelete = (subCatindex, index) => {
    const existingQuestions = [...subCategory];
    existingQuestions[subCatindex].questions.splice(index, 1);
    setSubCategory(existingQuestions);
  };

  const handleSelectKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const addSubcategory = () => {
    const existingSubCategory = [...subCategory];
    existingSubCategory.push({
      name: "",
      _id: null,
      questions: [],
    });
    setSubCategory(existingSubCategory);
  };
  const handleBlur = (
    idx,
    question,
    options,
    setOptions,
    handleQuestionChange,
    inputValue,
    setInputValue
  ) => {
    if (inputValue) {
      const newOption = { label: inputValue, value: inputValue };
      if (!options.some((option) => option.value === inputValue)) {
        setOptions([...options, newOption]);
      }
      setInputValue("");
    }
  };

  const handlePressEnter = (
    event,
    idx,
    question,
    options,
    setOptions,
    handleQuestionChange,
    inputValue,
    setInputValue
  ) => {
    event.preventDefault();
    event.stopPropagation();
    handleBlur(
      idx,
      question,
      options,
      setOptions,
      handleQuestionChange,
      inputValue,
      setInputValue
    );
  };

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputValue]);

  const handleSave = () => {
    const data = [
      {
        category: category,
        _id: category._id,
        subCategories: subCategory,
      },
    ];
    setLoading(true);
    dispatch(createNewSymptom(data)).then((res) => {
      setLoading(false);
      if (res.meta.requestStatus === "fulfilled") {
        navigate("/symptomsQuestion");
      }
    });
    console.log(data);
  };
  const handleSubCatChange = (scidx, value) => {
    const existingSubCategory = [...subCategory];
    existingSubCategory[scidx].name = value;
    setSubCategory(existingSubCategory);
  };

  return (
    <Card
      title={id ? "Edit Symptom" : "Add New Symptom"}
      style={{ margin: 10 }}
      extra={
        <Button
          loading={loading}
          disabled={loading}
          onClick={handleSave}
          icon={<SaveOutlined />}
          type="primary"
        >
          {" "}
          Save{" "}
        </Button>
      }
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin /> &nbsp; Please wait, Loading.
        </div>
      ) : (
        <>
          <Row gutter={15}>
            <Col span={20}>
              <Input
                onChange={(e) =>
                  setCategory(prevState => ({
                    ...prevState, // Copy existing properties
                    name: e.target.value
                  }))
                }
                value={category.name}
                placeholder="Category Name"
              />
            </Col>

            <Col span={4}>
              <Button onClick={addSubcategory}>Add Sub Cateogry</Button>
            </Col>
          </Row>
          {subCategory.map((sub, scidx) => (
            <div
              key={scidx}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                borderTop: "1px solid rgb(211, 206, 206)",
                paddingTop: 20,
              }}
            >
              <h4>Sub Category</h4>

              <Input
                style={{ marginTop: 15 }}
                value={sub.name}
                onChange={(e) => handleSubCatChange(scidx, e.target.value)}
                placeholder="Type your subcategory"
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <h4>Questions</h4>
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => addQuestion(scidx)}
                >
                  Add Question
                </Button>
              </div>
              <div>
                {sub?.questions?.map(
                  (question, idx) =>
                    question.isActive && (
                      <>
                        <Row style={{ marginTop: 15 }} gutter={15}>
                          <Col span={23}>
                            <Input
                              placeholder="Type your question"
                              onChange={(e) =>
                                handleQuestionChange(
                                  scidx,
                                  idx,
                                  "question",
                                  e.target.value
                                )
                              }
                              value={question["question"]}
                            />
                          </Col>
                          <Col span={1}>
                            <Button
                              onClick={() =>
                                handleQuestionChange(
                                  scidx,
                                  idx,
                                  "isActive",
                                  false
                                )
                              }
                              icon={<DeleteOutlined />}
                              size={"medium"}
                            ></Button>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: 15 }} gutter={15}>
                          <Col span={12}>
                            <Select
                              style={{ width: "100%" }}
                              value={question.answerType}
                              onChange={(value) =>
                                handleQuestionChange(
                                  scidx,
                                  idx,
                                  "answerType",
                                  value
                                )
                              }
                              placeholder="Answer Type"
                              options={[
                                { label: "Radio", value: "radio" },
                                { label: "Yes Or No", value: "yn" },
                                { label: "Text", value: "text" },
                              ]}
                            />
                          </Col>

                          <Col span={12}>
                            <Select
                              mode="multiple"
                              style={{ width: "100%" }}
                              value={question.options}
                              onChange={(value) =>
                                handleQuestionChange(
                                  scidx,
                                  idx,
                                  "options",
                                  value
                                )
                              }
                              placeholder="Select Options"
                              options={options}
                              filterOption={filterOption}
                              onKeyDown={handleSelectKeyDown}
                              dropdownRender={(menu) => (
                                <div>
                                  {menu}
                                  <Input
                                    style={{ margin: 8, width: "90%" }}
                                    placeholder="Add new item and press enter"
                                    value={inputValue}
                                    onChange={(e) =>
                                      handleInputChange(e.target.value)
                                    }
                                    onBlur={() =>
                                      handleBlur(
                                        idx,
                                        question,
                                        options,
                                        setOptions,
                                        handleQuestionChange,
                                        inputValue,
                                        setInputValue
                                      )
                                    }
                                    onPressEnter={(event) =>
                                      handlePressEnter(
                                        event,
                                        idx,
                                        question,
                                        options,
                                        setOptions,
                                        handleQuestionChange,
                                        inputValue,
                                        setInputValue
                                      )
                                    }
                                  />
                                </div>
                              )}
                            />
                          </Col>
                        </Row>
                      </>
                    )
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </Card>
  );
}

export default Create;
