import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { ToastContainer, toast } from "react-toastify";

export const getList = createAsyncThunk(
  "targetedTherapy/getList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/targetedTherapy`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue("Error while getting list please Try Again Later");
    }
  }
);

export const updateTargetedTherapy = createAsyncThunk(
  "targetedTherapy/updateTargetedTherapy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/targetedTherapy`, data);
      dispatch(getList());
      toast.success("Updated successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while updating targetedTherapy list please Try Again Later"
      );
    }
  }
);
export const addTargetedTherapy = createAsyncThunk(
  "targetedTherapy/addTargetedTherapy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/targetedTherapy`, data);
      dispatch(getList());
      toast.success("Created successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in creating.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while adding targetedTherapy list please Try Again Later"
      );
    }
  }
);
export const deleteTargetedTherapy = createAsyncThunk(
  "targetedTherapy/deleteTargetedTherapy",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.delete(`/targetedTherapy/${data}`);
      dispatch(getList());
      toast.success("Deleted successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in deleting", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while deleting targetedTherapy list please Try Again Later"
      );
    }
  }
);
const initialState = {
  targetedTherapy: {
    loading: false,
    data: [],
  },
};

export const targetedTherapySlice = createSlice({
  name: "targetedTherapy",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getList.fulfilled, (state, action) => {
        state.targetedTherapy.data = action.payload;
        state.targetedTherapy.loading = false;
      })
      .addCase(getList.pending, (state, action) => {
        state.targetedTherapy.loading = true;
      })
      .addCase(getList.rejected, (state, action) => {
        state.targetedTherapy.loading = false;
      })
      // .addCase(updateTargetedTherapy.pending, (state, action) => {
      //   state.targetedTherapy.loading = true;
      // })
      // .addCase(updateTargetedTherapy.failed, (state, action) => {
      //   state.targetedTherapy.loading = false;
      // })
      // .addCase(updateTargetedTherapy.fulfilled, (state, action) => {
      //   state.targetedTherapy.loading = false;
      //   state.targetedTherapy.data = action.payload;
      // })
      
      
  },
});

export const {} = targetedTherapySlice.actions;
export default targetedTherapySlice.reducer;
