import React, { useEffect, useState, useRef } from "react";
import { getUserList } from "../../../redux/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Switch,
  Avatar,
  Card,
  Popconfirm,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockKeyhole,
  faUsers,
  faFlaskVial,
  faHospitalUser,
  faUserDoctor,
  faHandHoldingMedical,
  faPersonDotsFromLine,
  faNewspaper,
  faDna,
} from "@fortawesome/free-solid-svg-icons"; // Import specific icons

import {
  updateUserInfo,
  sendResetPasswordLink,
  getRolesList,
} from "../../../redux/usersSlice";
import moment from "moment";
function UserList(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.users.userList);
  const rolesList = useSelector((state) => state.users.rolesList);

  const [tableData, setTableData] = useState([]);
  const [currentScreenRoleId, setCurrentScreenRoleId] = useState("");

  useEffect(() => {
    dispatch(getRolesList()).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        const roleId = res.payload.filter(
          (role) => role.name.toLowerCase() === props.kind.toLowerCase()
        );
        if (roleId.length) {
          setCurrentScreenRoleId(roleId[0]?._id);
          dispatch(getUserList(roleId[0]?._id));
        }
      }
    });
  }, []);

  const handleProfileUpdate = (userinfo, key, val) => {
    console.log("The profile information is", userinfo);
    let userId = userinfo._id;

    let update = {};
    if (key === "isActive") {
      update = { isActive: val };
    } else {
      update = {
        settings: {
          isUserActivated: val,
        },
      };
    }
    dispatch(updateUserInfo({ userId: userId, data: { ...update } })).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(getUserList(currentScreenRoleId));
        }
      }
    );
  };

  const sendResetPass = (id) => {
    dispatch(sendResetPasswordLink(id));
  };

  useEffect(() => {
    let data = [];
    if (userData.data.length > 0) {
      userData.data.forEach((item, index) => {
        data.push({
          key: item._id,
          profilePhoto:
            item?.profile?.profilePhoto === "" ? (
              <Avatar>{item.firstName[0].toUpperCase()}</Avatar>
            ) : (
              <Avatar src={item?.profile?.profilePhoto} />
            ),

          id: item._id,
          name: item.firstName + " " + item.lastName,
          gender: item.profile.gender,
          dob: moment(item.profile.dob).format("DD-MM-YYYY"),
          country: item.profile.country,
          phoneNumber: item.phoneCode + item.phoneNumber,
          createdOn: moment(item.createdOn).format("DD-MM-YYYY HH:MM:SS"),
          email: item.email,
          isActive: (
            <Switch
              checkedChildren="Active"
              unCheckedChildren="InActive"
              checked={item.isActive}
              onChange={(val) => handleProfileUpdate(item, "isActive", val)}
            />
          ),
          isUserActivated: (
            <Switch
              checkedChildren="Verified"
              unCheckedChildren="Not Verified"
              checked={item?.settings?.isUserActivated}
              onChange={(val) =>
                handleProfileUpdate(item, "isUserActivated", val)
              }
            />
          ),
          action: (
            <Tooltip placement="top" title={"Send Password reset link"}>
              <Popconfirm
                title="Reset Password?"
                description="Are you sure to send a password reset link to user?"
                onConfirm={() => sendResetPass(item._id)}
                okText="Yes"
                cancelText="No"
              >
                <span
                  title="Send Password Reset Link"
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 20 , color: "#004e8b"}}
                    icon={faUnlockKeyhole}
                  />
                </span>
              </Popconfirm>
            </Tooltip>
          ),
        });
      });
      setTableData(data);
    }
  }, [userData.data]);

  const columns = [
    {
      title: "Avatar",
      dataIndex: "profilePhoto",
      key: "profilePhoto",
      width: "5%",
    },

    {
      title: "UserId",
      dataIndex: "id",
      key: "userid",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => a.gender.localeCompare(b.gender),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      sorter: (a, b) => a.dob.localeCompare(b.dob),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "PhoneNumber",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },

    {
      title: "CreatedOn",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Account Verfiied",
      dataIndex: "isUserActivated",
      key: "isUserActivated",
      fixed: "right",
      sorter: (a, b) => a.isUserActivated.localeCompare(b.isUserActivated),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "AccountStatus",
      dataIndex: "isActive",
      key: "isActive",
      fixed: "right",
      sorter: (a, b) => a.isActive.localeCompare(b.isActive),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      dataIndex: "action",
    },
  ];

  return (
    <Card
      title={`${props.kind} List`}
      style={{ margin: 15, maxHeight: "90vh" }}
    >
      <Table
        loading={userData.loading || rolesList.loading}
        columns={columns}
        dataSource={tableData}
        scroll={{
          x: 1800,
        }}
      />
      ;
    </Card>
  );
}

export default UserList;
