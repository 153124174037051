import axios from "axios";
import base64 from "base-64";
// Create a new Axios instance
console.log("API_URL", process.env.REACT_APP_API_URL);
const axiosInstance = axios.create({
  // baseURL: "http://localhost:5001/corev2/api/v1", // Set your base URL here
  baseURL: "https://dev.cureonco.com/corev2/api/v1", // Set your base URL here
  timeout: 30000, // Set a timeout (in milliseconds) for requests
});

// Optional: Add request interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers['x-tenant-id'] = "IN"
    config.headers['x-auth-token'] = base64.decode(localStorage.getItem("ACCESS_TOKEN"))
    config.headers['x-auth-chat-token'] = base64.decode(localStorage.getItem("CHAT_ACCESS_TOKEN"))
    // You can modify the request config here (e.g., add headers)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Add response interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data here
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
