import React, { useState } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  SolutionOutlined,
  MedicineBoxOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faUsers, faFlaskVial, faHospitalUser, faUserDoctor, faHandHoldingMedical, faPersonDotsFromLine, faNewspaper, faDna, faMessage} from '@fortawesome/free-solid-svg-icons'; // Import specific icons

import { Breadcrumb, Layout, Menu, theme } from "antd";
const { Header, Content, Sider } = Layout;

function ASider() {

  const [collapsed, setCollapsed] = useState(false);
  const handleMenuClick = (key) => {
    console.log("key", key.key);
    window.location.href = `/${key.key}`;
  };
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={250}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[window.location.href.split('/')[window.location.href.split('/').length - 1]]}
        defaultOpenKeys={["users"]}
        onClick={handleMenuClick}
        style={{
          height: "100%",
          borderRight: 0,
        }}
        items={[
          {
            key: `dashboard`,
            icon: React.createElement(SolutionOutlined),
            label: `Dashboard`,
          },
          {
            key: `users`,
            icon: <FontAwesomeIcon icon={faUsers} />,
            label: `Users`,
            children: [
              {
                key: `patients`,
                icon:<FontAwesomeIcon icon={faHospitalUser} />,
                label: `Patients`,
              },
              {
                key: `doctors`,
                icon:<FontAwesomeIcon icon={faUserDoctor} />,
                label: `Doctors`,
              },
              {
                key: `caregivers`,
                icon: <FontAwesomeIcon icon={faHandHoldingMedical} />,
                label: `Caregivers`,
              },
            ],
          },
          {
            key: `askdrQuestion`,
            icon: React.createElement(SolutionOutlined),
            label: `Ask Your Doctor Questions`,
          },
          {
            key: `symptomsQuestion`,
            icon: <FontAwesomeIcon icon={faPersonDotsFromLine} />,
            label: `Symptoms Questions`,
          },
          {
            
            key: `targetedTherapy`,
            icon: <FontAwesomeIcon icon={faFlaskVial} />,
            label: `Targeted Therapy`,
          },
          {
            
            key: `team`,
            icon: <FontAwesomeIcon icon={faPeopleGroup} />,
            label: `Team`,
          },
          {
            
            key: `oncoNews`,
            icon:<FontAwesomeIcon icon={faNewspaper} />,
            label: `OncoNews`,
          },
          {
            
            key: `knowyourdna`,
            icon: <FontAwesomeIcon icon={faDna} />,
            label: `KnowYourDNA`,
          },
          {
            
            key: `feedbacks`,
            icon: <FontAwesomeIcon icon={faMessage} />,
            label: `Feedbacks`,
          },
        ]}
      />
    </Sider>
  );
}

export default ASider;
