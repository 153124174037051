import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";

export const getFeedbacks = createAsyncThunk(
  "ecTeam/teamList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/feedbacks`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting team list please Try Again Later"
      );
    }
  }
);

const initialState = {
  feedbacks: {
    loading: false,
    data: [],
  },
};
export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacks.fulfilled, (state, action) => {
        state.feedbacks.data = action.payload;
        state.feedbacks.loading = false;
      })
      .addCase(getFeedbacks.pending, (state, action) => {
        state.feedbacks.loading = true;
      })
      .addCase(getFeedbacks.rejected, (state, action) => {
        state.feedbacks.loading = false;
      });
  },
});

export const {} = feedbackSlice.actions;
export default feedbackSlice.reducer;
