import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import QRCode from "react-qr-code";
import { Spin } from "antd";
import base64 from "base-64";

function QRLoginScanner(props) {
  const [loading, setLoading] = useState(false);
  const [qrGen, setQrGen] = useState(false);
  const [sec, setSec] = useState(null);
  useEffect(() => {
    connectSocket();
    return () => {
      // connectSocket();
      // socket.disconnect();
    };
  }, [props.show]);
  const connectSocket = () => {
    const socket = io("https://dev.cureonco.com/corev2", {
      withCredentials: true, // Crucial for sending cookies/credentials
      transports: ["polling", "websocket"], // Matches server's transport configuration.
      path: "/corev2/qrlogin", // Matches server's path configuration.
    }).on("connect_error", (error) => {
      console.error("Socket.IO connection error:", error);
      console.error("Error message:", error.message); // Get the error message
      if (error.description) {
        console.error("Error description:", error.description); // Sometimes provides more context
      }
      if (error.context) {
        console.error("Error context:", error.context);
      }
    });
    socket.on("connect", () => {
      console.log(
        "Client: Connected - Socket ID:",
        socket.id,
        "Transport:",
        socket.io.engine.transport.name
      );
    });
    socket.on("connect_error", (error) => {
      console.error("Client: Socket.IO connection error:", error);
      console.error("Client: Error message:", error.message);
      if (error.description) {
        console.error("Client: Error description:", error.description);
      }
      if (error.context) {
        console.error("Client: Error context:", error.context);
      }
    });

    socket.on("connect_timeout", () => {
      console.error("Client: Connection timeout");
    });

    socket.on("reconnect", (attemptNumber) => {
      console.log("Client: Reconnected - Attempt:", attemptNumber);
    });

    socket.on("reconnect_attempt", (attemptNumber) => {
      console.log("Client: Reconnecting - Attempt:", attemptNumber);
    });

    socket.on("reconnect_error", (error) => {
      console.error("Client: Reconnection error:", error);
    });

    socket.on("reconnect_failed", () => {
      console.error("Client: Reconnection failed");
    });

    socket.on("disconnect", (reason) => {
      console.log("Client: Disconnected - Reason:", reason);
    });
    socket.on("qr-data", (data) => {
      console.log("UNIQUE ID", data);
      setSec(JSON.stringify(data));
    });
    socket.on("login-loading", (data) => {
      setLoading(data);
    });
    socket.on("login-success", (data) => {
      console.log("Message From Backend", data);
      let { accessToken, userInformation, chatAccessToken } = data;

      localStorage.setItem("IS_AUTH", "1");
      localStorage.setItem("USER_INFO", JSON.stringify(userInformation));
      localStorage.setItem("ROLE", userInformation?.role?.name?.toLowerCase());
      localStorage.setItem("ACCESS_TOKEN", base64.encode(accessToken));
      localStorage.setItem("CHAT_ACCESS_TOKEN", base64.encode(chatAccessToken));
      if (userInformation?.role?.name?.toLowerCase() === "admin")
        window.location.href = "/";
      else window.location.href = "/filebrowser/CureoncoDoc";
    });
    socket.on("connect_failed", function () {
      console.log("Connection Failed");
      setQrGen(false);
    });
    socket.on("disconnect", () => {
      setQrGen(false);
      console.log("Disconnected from server");
    });
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {qrGen ? (
          <p>Generating QR Code...</p>
        ) : (
          <Spin spinning={loading} size="large" tip="Loading...">
            <div className="chat-app">
              {props.show && sec && (
                <QRCode level={"M"} fgColor="#0f3a64" value={sec} />
              )}
            </div>
          </Spin>
        )}
      </div>
    </>
  );
}

export default QRLoginScanner;
