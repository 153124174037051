import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Divider, Spin } from "antd";
import { Col, Row } from "antd";
import QRLoginScanner from "../../components/qrLoginScanner";
import CLogo from "../../assets/images/cureoncologo.png";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
function Login(props) {
  const [qrShow, setQrShow] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const dispatch = useDispatch();
  const toggleQR = () => {
    setQrShow(!qrShow);
  };
  const handleSave = (e) => {
    console.log("Form Values", e);
  };
  const handleLogin = () => {
    console.log("Username", username);
    dispatch(
      login({
        email: username,
        password: password,
        registrationType: "Native",
        notificationToken: "NOT_APPLICABLE",
      })
    );
  };
  const Form = (props) => (
    <div>
      <FormInput
        description="Username"
        placeholder="Enter your username"
        type="text"
        onChange={(e) => setUsername(e.target.value)}
        value={username}
      />
      <FormInput
        description="Password"
        placeholder="Enter your password"
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <FormButton onClick={handleLogin} title="Log in" />
    </div>
  );

  const FormButton = (props) => (
    <div id="button" className="row">
      <button>{props.title}</button>
    </div>
  );

  const FormInput = (props) => (
    <div className="row">
      <label>{props.description}</label>
      <input
        onChange={props.onChange}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
      />
    </div>
  );

  const OtherMethods = (props) => (
    <div id="alternativeLogin">
      <label>Or sign in with:</label>
      <div id="iconGroup">
        <Facebook />
        <Twitter />
        <Google />
      </div>
    </div>
  );

  const Facebook = (props) => <a href="#" id="facebookIcon"></a>;

  const Twitter = (props) => <a href="#" id="twitterIcon"></a>;

  const Google = (props) => <a href="#" id="googleIcon"></a>;

  return (
    <div className="main">
      <Spin spinning={auth.isLoading}>
        <div className="wrapper">
          <img
            style={{ width: "100%", marginBottom: "20px" }}
            src={CLogo}
            alt="logo"
          />

          <form onSubmit={handleSave}>
            <>
              <h3>Login</h3>
              {qrShow && (
                <>
                  <p>Scan the below code with CureOnco mobile app to login.</p>
                  <br />
                </>
              )}

              <QRLoginScanner show={qrShow} />
              {!qrShow && (
                <>
                  <Input
                    placeholder="Email Address"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    type="text"
                    required
                  />
                  <br/>
                  <Input.Password
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Your Password"
                    visibilityToggle={{
                      visible: passwordVisible,
                      onVisibleChange: setPasswordVisible,
                    }}
                  />

                  {/* <label>Enter your password</label>
                  </div> */}
                  {/* <div className="forget">
                    <label htmlFor="remember">
                      <input type="checkbox" id="remember" />
                      <p>Remember me</p>
                    </label>
                    <a href="#">Forgot password?</a>
                  </div> */}
                  <br />
                  <Button
                    style={{
                      background: "#144B81",
                      color: "white",
                      height: 40,
                      borderTopLeftRadius: "20px",
                      borderBottomRightRadius: "20px",
                      borderTopRightRadius: "20px",
                      borderBottomLeftRadius: "20px",
                    }}
                    onClick={handleLogin}
                    type=""
                  >
                    Log In
                  </Button>
                </>
              )}

              <Divider>Or</Divider>
              <Button
                style={{
                  background: "#144B81",
                  color: "white",
                  height: 40,
                  borderTopLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  borderTopRightRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                onClick={toggleQR}
                type=""
              >
                Login with {`${qrShow ? "Username Password" : "QR Code"}`}{" "}
              </Button>

              {/* <div className="register">
                <p>
                  Don't have an account? <a href="#">Register</a>
                </p>
              </div> */}
            </>
          </form>
        </div>
      </Spin>
    </div>
  );
}

export default Login;
