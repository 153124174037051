import React, { useEffect } from "react";
import aboutbg from "../../assets/images/about-bg.png";
import visionPt from "../../assets/images/vision-point.svg";
import missionBg from "../../assets/images/mission-bg.jpg";
import member1 from "../../assets/images/member-1.jpg";
import member2 from "../../assets/images/member-2.jpg";
import { getTeamList } from "../../redux/ecTeamSlice";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "antd";
function About() {
  const dispatch = useDispatch();
  const _data = useSelector((state) => state.ecTeam.teamList);
  useEffect(() => {
    dispatch(getTeamList());
  }, []);
  return (
    <>
      <section className="page-header-section">
        <div className="container page-header-container-divided">
          <div className="page-header-content">
            {/* <p className="page-header-badge">About Us</p> */}
            <h1 className="xl-text b-weight inverted-text r-b-m">
              We're a patient centric platform that helps throughout their
              treatment journey.
            </h1>
            <p className="r-text inverted-text ah-op">
              Patients with cancer have unmeet information needs and face
              multiple challenges during their treatment journey. There is no
              one patient centric approach, platform/app that supports them from
              during their treatment. Cureonco supports by giving them tools to
              manage their treatment, empower with knowledge, seamless access to
              their medical record and get second opinion{" "}
            </p>
          </div>
          <div className="page-header-img-container">
            <img src={aboutbg} className="about-bg" alt="about" />
          </div>
        </div>
      </section>

      <section className="vision-section">
        <div className="container">
          <div className="vision-grid">
            <div>
              <div className="section-header">
                <p className="badge">Our Mission</p>
                <h2 className="xl-text xb-weight">
                  Connect and Empower the patients with health record and
                  knowledge{" "}
                </h2>
                <p className="r-t-m r-text">
                  Usage of mobile interventions to help cancer patients get the
                  information they need to keep themselves in track of their
                  medical condition. Interventions seek to improve the
                  management of treatment-related symptoms, raise awareness of
                  their illness, and enhance symptom communication with
                  physicians.
                </p>
              </div>
              <div className="vision-points-container">
                <div className="vision-point">
                  <img
                    src={visionPt}
                    alt="vision"
                    className="vision-points-img"
                  />
                  <p className="r-text m-weight">
                    Patient reported outcome(PRO)
                  </p>
                </div>
                <div className="vision-point">
                  <img
                    src={visionPt}
                    alt="vision"
                    className="vision-points-img"
                  />

                  <p className="r-text m-weight">Access to health records</p>
                </div>
                <div className="vision-point">
                  <img
                    src={visionPt}
                    alt="vision"
                    className="vision-points-img"
                  />

                  <p className="r-text m-weight">Clinical trials</p>
                </div>
                <div className="vision-point">
                  <img
                    src={visionPt}
                    alt="vision"
                    className="vision-points-img"
                  />

                  <p className="r-text m-weight">Tumor-agnostic therapy</p>
                </div>
                <div className="vision-point">
                  <img
                    src={visionPt}
                    alt="vision"
                    className="vision-points-img"
                  />

                  <p className="r-text m-weight">Genetic testing</p>
                </div>
              </div>
            </div>
            <div className="vision-img-container">
              <img src={missionBg} alt="mission" className="vision-img" />
            </div>
          </div>
        </div>
      </section>

      <section className="team-section">
        <div className="container">
          <div className="section-header-center">
            <p className="badge">Our Team</p>
            <h2 className="xl-text xb-weight">
              We&apos;ve build our team with top medical and IT experts
            </h2>
          </div>

          <div className="team-grid">
            {_data.data.map((item, index) => (
              <div className="team-grid-item">
                <div className="member-image-container">
                  <Image
                    src={item?.picture || "https://e7.pngegg.com/pngimages/753/432/png-clipart-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-thumbnail.png"}
                    style={{ height: 220, width: 220, color: "red" }}
                    alt="member"
                    className="member-image"
                  />
                </div>
                <p className="r-text m-weight">{item.name}</p>
                <p className="xs-text h-op">{item.designation}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
