import React from "react";
import logo from "../../assets/images/logo.png";

import { Avatar } from "antd";
import { Layout } from "antd";
const { Header } = Layout;

function AHeader() {
  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span onClick={() => (window.location.href = "/")}>
          <img
            src={logo}
            alt="logo"
            style={{ height: "30px", width: "150px" }}
          />
        </span>
        <div style={{ cursor: "pointer" }}>
          <Avatar
            onClick={() => {
              window.localStorage.clear();
              window.location.href = "/";
            }}
          >
            {JSON.parse(localStorage.getItem("USER_INFO"))?.user?.firstName[0]}
          </Avatar>
        </div>
      </div>
    </Header>
  );
}

export default AHeader;
