import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestions,
  updateQuestions,
  deleteCategory,
  create,
} from "../../../redux/askYourDrSlice";
import { Collapse, Card, Button, Modal, Popconfirm, Spin } from "antd";
import {
  DeleteOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";

import { Input } from "antd";
const { TextArea } = Input;
function AskDrQuestion() {
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.askYourDr.questionList);
  const [questionsList, setQuestionsList] = useState([]);
  const [newQuestionsList, setNewQuestiosnList] = useState([]);
  const [editedQuestions, setEditedQuestions] = useState([]);
  const [newCat, setNewCat] = useState(null);
  const [isNewCatModalVisible, setIsNewCatModalVisible] = useState(false);
  const [editCat, setEditCat] = useState(null);
  useEffect(() => {
    dispatch(getQuestions());
  }, []);
  useEffect(() => {
    if (questions.data.length > 0) {
      setQuestionsList(questions.data);
    }
  }, [questions.data]);
  const changeQuestionValue = (id, value, cat, kind) => {
    let temp = JSON.parse(JSON.stringify(questionsList));
    let index = temp.findIndex((item) => item.category === cat);
    temp[index].questions[id].question = value;
    temp[index].questions[id].isActive = kind === "delete" ? false : true;
    setQuestionsList(temp);
    setEditCat(cat);

    let _editedQuestion = [...editedQuestions];

    const existingIndex = _editedQuestion.findIndex(
      (item) => item.id === temp[index].questions[id]?._id
    );

    const newQuestionData = {
      question: value,
      isActive: kind === "delete" ? false : true,
      category: cat,
      id: temp[index].questions[id]?._id,
    };

    if (existingIndex === -1) {
      // ID does not exist, add it
      _editedQuestion.push(newQuestionData);
    } else {
      // ID exists, update it
      _editedQuestion[existingIndex] = newQuestionData;
    }

    console.log("editedQuestions", _editedQuestion);
    setEditedQuestions(_editedQuestion);
  };
  const addNewQuestion = (cat) => {
    let temp = JSON.parse(JSON.stringify(questionsList));
    let index = temp.findIndex((item) => item.category === cat);
    temp[index].questions.push({
      question: "",
      isActive: true,
      category: cat,
      isNew: true,
    });
    setQuestionsList(temp);
  };
  const saveEditedQuestions = () => {
    console.log("editedQuestions", editedQuestions);
    dispatch(updateQuestions(editedQuestions));
    setEditCat(null);
  };
  const _deleteCategory = (category) => {
    dispatch(deleteCategory({ category: category }));
  };
  const genExtra = (item) => {
    return (
      <>
        {editedQuestions !== null &&
        editedQuestions.length > 0 &&
        item.category === editCat ? (
          <Button
            onClick={() => {
              saveEditedQuestions();
            }}
            type="primary"
            icon={<SaveOutlined />}
            size={"medium"}
          ></Button>
        ) : null}
        <Popconfirm
          title="Delete the category"
          description="Are you sure to delete this category?"
          onConfirm={() => {
            _deleteCategory(item.category);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button icon={<DeleteOutlined />} size={"medium"}></Button>
        </Popconfirm>
        <Button
          onClick={() => addNewQuestion(item.category)}
          icon={<PlusCircleOutlined />}
          size={"medium"}
        ></Button>
      </>
    );
  };
  const addNewCategory = () => {
    setIsNewCatModalVisible(false);
    setNewCat(null);
    let temp = JSON.parse(JSON.stringify(questionsList));
    temp.push({ title: newCat, data: [] });
    setQuestionsList(temp);
    toast.success("New Category added, Please add questions to save.", {
      position: "bottom-center",
    });
  };
  const handleNewCategoryCancel = () => {
    setIsNewCatModalVisible(false);
    setNewCat(null);
  };
  const _addNewQuestion = () => {
    setNewQuestiosnList([
      ...newQuestionsList,
      { category: newCat, question: "" },
    ]);
  };
  const handleQuestionChange = (value, index) => {
    let questions = [...newQuestionsList];
    questions[index].question = value;
    setNewQuestiosnList(questions);
  };

  const removeQuestion = (idx) => {
    let questions = newQuestionsList
      .slice(0, idx)
      .concat(newQuestionsList.slice(idx + 1));
    setNewQuestiosnList(questions);
  };
  const createNewQuestions = () => {
    dispatch(create([...newQuestionsList])).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsNewCatModalVisible(false);
      }
    });
  };

  return (
    <>
      <Modal
        title="Add New Questions"
        open={isNewCatModalVisible}
        onOk={createNewQuestions}
        onCancel={handleNewCategoryCancel}
      >
        <Input
          placeholder="Name of new category"
          value={newCat}
          onChange={(e) => setNewCat(e.target.value)}
        />
        <div style={{ marginTop: "20px", overflowY: "auto" }}>
          {newCat && (
            <>
              <h2>Questions</h2>
              {newQuestionsList.length <= 0 && (
                <p>
                  Please add some questions to this category by clicking add
                  question button below.
                </p>
              )}
            </>
          )}
          {newQuestionsList.map((item, index) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Input
                style={{ width: "90%" }}
                placeholder="Type your question here"
                value={newQuestionsList[index].question}
                onChange={(e) => {
                  handleQuestionChange(e.target.value, index);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  removeQuestion(index);
                }}
                style={{ fontSize: 20 }}
                type="primary"
              />
            </div>
          ))}
        </div>
        {newCat && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => _addNewQuestion()}
              icon={<PlusCircleOutlined />}
              size={"medium"}
            >
              Add Question
            </Button>
          </div>
        )}
      </Modal>
      <Spin tip="Loading" size="large" spinning={questions.loading}>
        <Card
          style={{
            width: "95%",
            margin: "1%",
            overflow: "auto",
            maxHeight: "90vh",
            height: "87vh",
          }}
          title={"Ask your doctor questions"}
          extra={
            <Button
              onClick={() => setIsNewCatModalVisible(true)}
              icon={<PlusCircleOutlined />}
              size={"medium"}
              type="primary"
            >
              Add Questions
            </Button>
          }
        >
          {questionsList?.map((question, index) => (
            <div key={index}>
              <Collapse
                collapsible="header"
                items={[
                  {
                    key: "1",
                    label: <p>{question?.category} - ({question.questions.length})</p>,
                    extra: genExtra(question),
                    children: (
                      <div>
                        {question?.questions?.length > 0 ? (
                          question?.questions?.map(
                            (item, index) =>
                              item?.isActive && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TextArea
                                      placeholder="Enter your question here."
                                      autoSize={{
                                        minRows: 2,
                                        maxRows: 6,
                                      }}
                                      value={item.question?.en}
                                      onChange={(e) =>
                                        changeQuestionValue(
                                          index,
                                          e.target.value,
                                          question.category
                                        )
                                      }
                                      style={{ width: "90%" }}
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Popconfirm
                                        title="Delete the question"
                                        description="Are you sure to delete this question?"
                                        onConfirm={() => {
                                          changeQuestionValue(
                                            index,
                                            item.category,
                                            question.category,
                                            "delete"
                                          );
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{ fontSize: 20 }}
                                        />
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                          )
                        ) : (
                          <Button
                            onClick={() => addNewQuestion(question.title)}
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            size={"medium"}
                          >
                            Add New Questions
                          </Button>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
              <br />
            </div>
          ))}
        </Card>
      </Spin>
    </>
  );
}

export default AskDrQuestion;
