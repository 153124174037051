import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { ToastContainer, toast } from "react-toastify";

export const getRolesList = createAsyncThunk(
  "user/rolesList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user/roles`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting users List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting user list please Try Again Later"
      );
    }
  }
);
export const getUsersCountByRoles = createAsyncThunk(
  "user/rolesListByCount",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user/user_by_roles`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting users List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting user list please Try Again Later"
      );
    }
  }
);
export const getUserList = createAsyncThunk(
  "user/userList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/user/${data}`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting users List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting user list please Try Again Later"
      );
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(
        `/auth/update_account_adm/${data.userId}`,
        data.data
      );
      // dispatch(getUserList("Patient"));
      toast.success("Updated successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error("Error in updating List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while updating user list please Try Again Later"
      );
    }
  }
);

export const sendResetPasswordLink = createAsyncThunk(
  "user/sendResetPasswordLink",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `/auth/send_password_reset_email/${id}`
      );
   
      return res.data;
      
    } catch (err) {
      toast.error("Error in sending link.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while updating user list please Try Again Later"
      );
    }
  }
);

const initialState = {
  userList: {
    loading: false,
    data: [],
  },
  rolesList: {
    loading: false,
    data: []
  },
  usersByRoleCount:{
    loading: false,
    data:[]
  }
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList.data = action.payload;
        state.userList.loading = false;
       
      })
      .addCase(getUserList.pending, (state, action) => {
        state.userList.loading = true;
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.userList.loading = false;
        toast.error("Error in getting user list", {
          position: "bottom-center",
        });
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.userList.loading = false;
      })
      .addCase(updateUserInfo.pending, (state, action) => {
        state.userList.loading = true;
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.userList.loading = false;
        toast.error("Error in updating user list", {
          position: "bottom-center",
        });
      })
      .addCase(sendResetPasswordLink.fulfilled, (state, action) => {
        state.userList.loading = false;
        toast.success("Password reset link sent to the user.", {
          position: "bottom-center",
        });
      })
      .addCase(sendResetPasswordLink.pending, (state, action) => {
        state.userList.loading = true;
      })
      .addCase(sendResetPasswordLink.rejected, (state, action) => {
        state.userList.loading = false;
        toast.error("Error in sending link to the user", {
          position: "bottom-center",
        })
      })
      .addCase(getRolesList.fulfilled, (state, action) => {
        console.log("ACTION", action.payload)
        state.rolesList.loading = false
        state.rolesList.data = action.payload
      })

      .addCase(getRolesList.pending, (state, action) => {
        state.rolesList.loading = true
      })

      .addCase(getRolesList.rejected, (state, action) => {
        state.rolesList.loading = false
      })
      .addCase(getUsersCountByRoles.fulfilled, (state, action) => {
        console.log("ACTION", action.payload)
        state.usersByRoleCount.loading = false
        state.usersByRoleCount.data = action.payload
      })

      .addCase(getUsersCountByRoles.pending, (state, action) => {
        state.usersByRoleCount.loading = true
      })

      .addCase(getUsersCountByRoles.rejected, (state, action) => {
        state.usersByRoleCount.loading = false
      })
  },
});

export const {} = userSlice.actions;
export default userSlice.reducer;
