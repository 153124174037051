import React, { useEffect, useState } from "react";
import {
  getTeamList,
  updateTeamInfo,
  addTeamMember,
} from "../../../redux/ecTeamSlice";
import { useSelector, useDispatch } from "react-redux";
import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Space,
  Table,
  Modal,
  Card,
  Image,
  Form,
  Upload,
  Popconfirm,
} from "antd";
import { uploadFiles } from "../../../redux/uploadFilesSlice";
import moment from "moment";

export default function Team() {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const _data = useSelector((state) => state.ecTeam.teamList);
  const [editInfo, setEditInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(null);

  useEffect(() => {
    dispatch(getTeamList());
  }, []);
  useEffect(() => {
    let data = [];
    if (_data?.data?.length > 0) {
      _data.data.forEach((item, index) => {
        data.push({
          key: item.id,
          picture: (
            <Image
              width={50}
              height={50}
              src={item.picture}
              style={{ borderRadius: "50%" }}
            />
          ),
          name: item.name,
          designation: item.designation,
          createdOn: moment(item.createdOn).format("DD-MM-YYYY HH:mm:ss"),
          updateOn: moment(item.updatedOn).format("DD-MM-YYYY HH:mm:ss"),
          updatedBy: item.updatedBy?.email,
          createdBy: item.createdBy?.email,
          action: (
            <Space>
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                  setEditInfo(item);
                }}
                type="primary"
              >
                Edit
              </Button>
              <Popconfirm
                title="Delete the user"
                description="Are you sure to delete this user?"
                onConfirm={() => {
                  dispatch(updateTeamInfo({ id: item._id, isActive: false }));
                }}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger">Delete</Button>
              </Popconfirm>
            </Space>
          ),
        });
      });
      console.log("Dataasasd", data);

      setTableData(data);
    }
  }, [_data.data]);
  const columns = [
    {
      title: "Picture",
      dataIndex: "picture",
      key: "picture",
      // width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),

      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: (a, b) => a.createdOn && a.createdOn.localeCompare(b.createdOn),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: (a, b) => a.createdBy && a.createdBy.localeCompare(b.createdBy),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Updated On",
      dataIndex: "updateOn",
      key: "updateOn",
      sorter: (a, b) => a.updateOn && a.updateOn.localeCompare(b.updateOn),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      sorter: (a, b) => a.updatedBy && a.updatedBy.localeCompare(b.updatedBy),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      dataIndex: "action",
    },
  ];
  const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
  };
  const normFile = (e) => {
    console.log("Upload event:", e);
    setUpdateProfile(e.file);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    if (Object.keys(editInfo).length === 0) {
      console.log("In add new", values);
      if (updateProfile) {
        dispatch(
          uploadFiles({
            file: updateProfile,
            callback: addCallback,
            defValues: values,
          })
        );
      } else {
        addCallback("", values);
      }
    } else {
      console.log(updateProfile);
      if (updateProfile) {
        dispatch(
          uploadFiles({ file: updateProfile, callback, defValues: values })
        );
      } else {
        callback(editInfo.picture, values);
      }
    }
  };
  const addCallback = (picURL, values) => {
    console.log("the values -", values, picURL);
    dispatch(
      addTeamMember({
        name: values.name,
        designation: values.designation,
        picture: picURL ?? "",
      })
    );
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };
  const callback = (uploadedInfo, values) => {
    console.log("the values -", values, uploadedInfo);
    dispatch(
      updateTeamInfo({
        id: editInfo._id,
        name: values.name,
        designation: values.designation,
        picture: uploadedInfo,
      })
    );
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setEditInfo({});
    setUpdateProfile(null);
  };
  return (
    <Card
      title={`EC Members`}
      extra={<Button onClick={() => setIsModalVisible(true)}>Add New</Button>}
      style={{ margin: 15, maxHeight: "90vh" }}
    >
      <Modal
        title={`${Object.keys(editInfo).length > 0 ? "Edit" : "Add"} `}
        open={isModalVisible}
        footer={null}
        // onOk={handleaddNew}
        onCancel={handleCancel}
      >
        <Form
          name="validate_other"
          initialValues={editInfo}
          {...formItemLayout}
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Designation"
            name="designation"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {Object.keys(editInfo).length !== 0 && (
            <Form.Item label="Current Picture" name="picture">
              <Image
                src={editInfo.picture}
                height={200}
                width={200}
                style={{ borderRadius: "50%" }}
              />
            </Form.Item>
          )}

          <Form.Item label="Update Picture">
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                name="files"
                action={() => {
                  console.log("Upload");
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Update the profile picture of the team member
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              span: 12,
              offset: 6,
            }}
          >
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              {/* <Button htmlType="reset">reset</Button> */}
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        loading={_data.loading}
        columns={columns}
        dataSource={tableData}
        scroll={{
          x: 1300,
        }}
      />
      ;
    </Card>
  );
}
