import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import { ToastContainer, toast } from "react-toastify";

export const getQuestions = createAsyncThunk(
  "symptoms/questionList",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/symptoms`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);
export const updateQuestions = createAsyncThunk(
  "symptoms/updatequestionList",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.put(`/symptom/questions`, data);
      dispatch(getQuestions());
      toast.success("Updated successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error(
        "Error while updating questions list please Try Again Later",
        {
          position: "bottom-center",
        }
      );
      return rejectWithValue(
        "Error while updating questions list please Try Again Later"
      );
    }
  }
);
export const createNewSubCategory = createAsyncThunk(
  "symptoms/createNewSubCategory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(
        `/symptom/questions/subCategory`,
        data
      );
      dispatch(getQuestions());
      toast.success("Created successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error(
        "Error while creation subcategory list please Try Again Later",
        {
          position: "bottom-center",
        }
      );
      return rejectWithValue(
        "Error while updating questions list please Try Again Later"
      );
    }
  }
);
export const createNewCategory = createAsyncThunk(
  "symptoms/createNewCategory",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.post(`/symptom/questions/category`, data);
      dispatch(getQuestions());
      toast.success("Created successfully.", {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      toast.error(
        "Error while creation new category list please Try Again Later",
        {
          position: "bottom-center",
        }
      );
      return rejectWithValue(
        "Error while updating questions list please Try Again Later"
      );
    }
  }
);
export const getCategoryById = createAsyncThunk(
  "symptoms/getCategoryById",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const res = await axiosInstance.get(`/symptoms/category/${id}`);
      return res.data.data;
    } catch (err) {
      toast.error("Error, please Try Again Later", {
        position: "bottom-center",
      });
      return rejectWithValue("Error, please Try Again Later");
    }
  }
);
export const getAnswerOptions = createAsyncThunk(
  "symptoms/answerOptions",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/symptoms/answer_options`);
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);

export const createNewSymptom = createAsyncThunk(
  "symptoms/newSymptom",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `/symptoms/add_symptoms_questionaire`,
        data
      );
      toast.success(
        data?._id
          ? "Symptoms updated successfully"
          : "New Symptoms Created Successfully.",
        {
          position: "bottom-center",
        }
      );
      return res.data.data;
    } catch (err) {
      toast.error("Error in getting List.", {
        position: "bottom-center",
      });
      return rejectWithValue(
        "Error while getting questions list please Try Again Later"
      );
    }
  }
);

const initialState = {
  questionList: {
    loading: false,
    data: [],
  },
  answerOptions: {
    loading: false,
    data: [],
  },
};

export const symptomSlice = createSlice({
  name: "symptoms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuestions.fulfilled, (state, action) => {
        state.questionList.data = action.payload;
        state.questionList.loading = false;
      })
      .addCase(getQuestions.pending, (state, action) => {
        state.questionList.loading = true;
      })
      .addCase(getQuestions.rejected, (state, action) => {
        state.questionList.loading = false;
      })
      .addCase(getAnswerOptions.fulfilled, (state, action) => {
        state.answerOptions.data = action.payload;
        state.answerOptions.loading = false;
      })
      .addCase(getAnswerOptions.pending, (state, action) => {
        state.answerOptions.loading = true;
      })
      .addCase(getAnswerOptions.rejected, (state, action) => {
        state.answerOptions.loading = false;
      });
  },
});

export const {} = symptomSlice.actions;
export default symptomSlice.reducer;
