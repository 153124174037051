import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto"; // Important to register controllers
import axiosInstance from "../config.axios";

const SignupTrendsChart = ({ roleId }) => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drillDownMonth, setDrillDownMonth] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log("ERROR -", roleId);
        if (!roleId) throw new Error("Something went wrong.");

        const queryParams = drillDownMonth ? `?month=${drillDownMonth}` : "";
        const response = await axiosInstance.get(
          `/user/get_user_singup_trend/${roleId}${queryParams}`
        );

        const data = response.data.data;

        const labels = data.map((item) => item.date);
        const counts = data.map((item) => item.count);

        setChartData({
          labels: labels,
          datasets: [
            {
              label: drillDownMonth ? "Daily Signups" : "Monthly Signups",
              data: counts,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        });
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (roleId) {
      fetchData();
    } else {
      setError("No Doctors.");
      setLoading(false);
    }
  }, [roleId, drillDownMonth]);
  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const clickedIndex = elements[0].index;
      const clickedMonth = chartData.labels[clickedIndex];
      if (!drillDownMonth) {
        setDrillDownMonth(clickedMonth); // Drill down to daily data
      } else {
        setDrillDownMonth(null); // Return to monthly view
      }
    }
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {chartData && (
        <Line
          data={chartData}
          options={{
            onClick: handleChartClick,
            scales: {
              x: {
                title: {
                  display: true,
                  text: drillDownMonth ? "Day" : "Month",
                },
              },
              y: {
                ticks: {
                  stepSize: 1,
                  precision: 0,
                },
                title: {
                  display: false,
                  text: "Number of Signups",
                },
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false, // Disable legend
              },
              title: {
                display: true,
                text: drillDownMonth
                  ? `Daily Signups for ${drillDownMonth}`
                  : `Monthly Signups (Click month to view days)`,
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return `Signups: ${context.parsed.y}`;
                  },
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      )}
    </div>
  );
};

export default SignupTrendsChart;
