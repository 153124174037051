import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../config.axios";
import base64 from "base-64";
import { ToastContainer, toast } from "react-toastify";

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/login", data);
      toast.info(res.data.message, {
        position: "bottom-center",
      });
      return res.data.data;
    } catch (err) {
      console.log("Error", err);
      alert(err.response.data.message);
      return rejectWithValue("Error while login please Try Again Later");
    }
  }
);
export const singup = createAsyncThunk(
  "auth/signup",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/auth/signup", data);
      return res.data;
    } catch (err) {
      return rejectWithValue("Error while login please Try Again Later");
    }
  }
);
const initialState = {
  isLoading: false,
  userInfo: {},
  isAuth: false,
  isLoginModalShown: false,
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleLoginModal: (state, action) => {
      state.isLoginModalShown = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        localStorage.setItem("IS_AUTH", "1");
        localStorage.setItem("USER_INFO", JSON.stringify(action.payload));
        localStorage.setItem("ROLE", action.payload.role.toLowerCase());
        localStorage.setItem(
          "ACCESS_TOKEN",
          base64.encode(action.payload.accessToken)
        );
        localStorage.setItem(
          "CHAT_ACCESS_TOKEN",
          base64.encode(action.payload.chatAccessToken)
        );
        if (action.payload.role.toLowerCase() === "admin") window.location.href = "/";
        else window.location.href = "/filebrowser/CureoncoDoc";
      })
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(singup.fulfilled, (state, action) => {
        state.isLogin = action.payload.isLogin;
        state.userInfo = action.payload.userinfo;
        localStorage.setItem("IS_AUTH", "1");
        localStorage.setItem("USER_INFO", action.payload.userinfo);
      })
      .addCase(singup.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(singup.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { toggleLoginModal } = authSlice.actions;
export default authSlice.reducer;
