import React, { useEffect } from "react";
import { Card, Col, Row, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  UserOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { getUsersCountByRoles } from "../../../redux/usersSlice";
import {
  updateUserInfo,
  sendResetPasswordLink,
  getRolesList,
} from "../../../redux/usersSlice";
import SignupTrendsChart from "../../../components/SignupTrendsChart";
function Landing() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRolesList());
    dispatch(getUsersCountByRoles());
  }, []);
  const userState = useSelector((state) => state.users);
  const getCounts = (role) => {
    return userState.usersByRoleCount.data.filter(
      (item) => item.role === role
    )?.[0];
  };
  const getRoleId = (role) => {
    return userState.rolesList.data.filter((item) => item.name === role)?.[0];
  };
  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={15}>
        <Col span={8}>
          <Card variant="borderless">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#004e8b" }}>
                <UserOutlined />
                &nbsp;&nbsp; Patients
              </h2>
              <span style={{ fontSize: 40, fontWeight: 500, color: "#004e8b" }}>
                {getCounts("Patient")?.["users"] ?? 0}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card variant="borderless">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#004e8b" }}>
                <UsergroupAddOutlined />
                &nbsp;&nbsp; Doctors
              </h2>
              <span style={{ fontSize: 40, fontWeight: 500, color: "#004e8b" }}>
                {getCounts("Physician")?.["users"] ?? 0}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card variant="borderless">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 style={{ color: "#004e8b" }}>
                <HeartOutlined />
                &nbsp;&nbsp; Caregivers
              </h2>
              <span style={{ fontSize: 40, fontWeight: 500, color: "#004e8b" }}>
                {getCounts("Caregiver")?.["users"] ?? 0}
              </span>
            </div>
          </Card>
        </Col>
      </Row>
      {userState.rolesList.loading ? (
        <Spin />
      ) : (
        <>
          <Row gutter={15} style={{ marginTop: 15 }}>
            <Col span={12}>
              <Card title="Patient Signups" style={{ height: "400px" }}>
                <SignupTrendsChart roleId={getRoleId("Patient")?._id ?? null} />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Doctor Signups" style={{ height: "400px" }}>
                <SignupTrendsChart roleId={getRoleId("Physician")?._id ?? null} />
              </Card>
            </Col>
          </Row>
          <Row
            gutter={15}
            style={{ marginTop: 15, display: "flex", justifyContent: "center" }}
          >
            <Col span={12}>
              <Card title="Caregiver Signups" style={{ height: "400px" }}>
                <SignupTrendsChart roleId={getRoleId("Caregiver")?._id ?? null} />
              </Card>
            </Col>
          </Row>
        </>
      )}
      <br />
      <br />
    </div>
  );
}

export default Landing;
